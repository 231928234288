.JustLoading-Container {
  position: absolute;
  width: 100%;
  opacity: 1;
  .JustLoading-Background {
    background-color: rgba(230, 230, 230, 1);
    opacity: 0.55;
    z-index: 999;
    position: absolute;
    width: 100%;
  }
  .JustLoading-Spinner {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  #JustLoading-Title {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    font-size: 20px;
  }
}
