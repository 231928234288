.AccountingVoyage-Container {
    width: 100%;
    height: 100%;
    #HeaderText {
    }
    .AccountingVoyage-Content-Header {
        position: relative;
        width: 100%;
        height: 50px;
        .AccountingVoyage-AddRate {
            position: absolute;
            right: 0;
        }
    }
    .AccountingVoyage-Content-Container {
        .AccountingVoyage-Table-Container {
            height: 650px;
            overflow-y: auto;
            .JustTable-Container{
                width: 1700px;
            }
            td,
            th {
                &:nth-child(2) {
                    // id
                    width: 4%;
                }
                &:nth-child(3) {
                    // name
                    width: 6%;
                }
                &:nth-child(4) {
                    // a.code
                    width: 13%;
                }
                &:nth-child(5) {
                    // a.name
                    width: 27%;
                }
                &:nth-child(6) {
                    // a.rentCode
                    width: 13%;
                }
                &:nth-child(7) {
                    // r.name
                    width: 27%;
                }
            }
        }
    }
}
