.RebateRate-Container {
    width: 100%;
    height: 100%;
    #HeaderText {
    }
    .RebateRate-Content-Header {
        position: relative;
        width: 100%;
        height: 50px;
        .RebateRate-AddRate {
            position: absolute;
            right: 0;
        }
    }
    .RebateRate-Content-Container {
        .RebateRate-Table-Container {
            height: 650px;
            overflow-y: auto;
        }
    }
}
