@import "../../../../CoreStyle/CoreStyle";
.YesNoModal {
  #YesNoMessage {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 15px;
  }
  #InfoMessage {
    color: $noRed;
    margin: 0;
    margin-top: 5px;
    // margin-bottom: 5px;
    padding: 0 !important;
    font-size: 14px;
  }
  .headerYesOrNo.disableClose {
    button {
      display: none;
    }
  }
  .YesNoModal-YesButton {
    float: left !important;
    background: $yesGreen !important;
    width: 120px !important;
    font-size: 16px !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    font-family: $boldFont;
    height: 35px;
  }
  .W180-Button{
    width: 180px !important;
  }

  .YesNoModal-YesButton.disabled {
    background: #e4e5e4 !important;
  }
  .YesNoModal-NoButton {
    float: right;
    background: $noRed;
    width: 120px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    height: 35px;
    // margin-bottom: 10px;
  }
  #butonSpin {
    width: 12px !important;
    height: 12px !important;
  }
  div:nth-child(2) {
    padding-bottom: 5px !important;
  }
}
.YesNoModal[role="dialog"] {
  min-width: 400px;
  min-height: 150px;
  // background-color: red;
  div:nth-child(2) {
    padding-bottom: 0px !important;
  }
}
