.Customer-Container {
  //100vh - 60px - 10px - 20px TableContainer
  position: relative;
  max-height: calc((100vh - 60px - 10px - 20px - 30px));
  .Customer-Table-Header {
    #Customer-HeaderName {
      display: inline-block;
      margin: 0;
    }
    #Table-SaveButton {
      float: right;
      margin-bottom: 10px;
    }
    #Table-ProcessButton {
      float: right;
      margin-right: 10px;
    }
  }

  .Customer-Table-Container {
    width: 100%;
    max-height: calc((100vh - 60px - 200px - 10px - 20px - 30px));
    overflow: auto;
    .JustTable-Container {
      width: 2150px;

      td,
      th {
        // &:nth-child(1) {
        //   // customer name
        //   width: 6%;
        // }
        &:nth-child(3) {
          //name
          width: 6%;
        }
        &:nth-child(4) {
          width: 8%;
        }
         &:nth-child(5) {
           //octet
           width: 6%;
         }
        &:nth-child(6) {
          //Buyer Account Number
          width: 8%;
        }
        &:nth-child(7) {
          //Vendor Account Number
          width: 8%;
        }
        &:nth-child(8) {
          //Email
          width: 12.5%;
        }
        &:nth-child(9) {
          width: 7%;
        }
        &:nth-child(10) {
          width: 7.5%;
        }
        &:nth-child(11) {
          width: 7.5%;
        }
      }
    }
  }
  .JustTable-ButtonContainer {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: top;
    float: right;
    .JustTable-SaveAll-Button {
      height: 30px;
      font-size: 14px;
      // margin-left: 10px;
    }
  }
}
