.EditInvoiceModalFooter-Container {
    position: absolute;
    bottom: 0;
    left: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    width: calc(100% - 40px);
    height: 50px;
    padding: 10px;
    .NewInvoiceModal-SaveButton {
        float: right;
    }
}
