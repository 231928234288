.AccountNumber-Table-Container {
  .JustTable-Container {
    td,
    th {
      &:nth-child(1) {
        // currency
        width: 20%;
      }
      &:nth-child(2) {
        //buyer account number
        width: 40%;
      }
      &:nth-child(3) {
        //vecdont account number
        width: 40%;
      }
    }
  }
}
