.InvoiceControl-Container {
  position: relative;
  max-height: calc((100vh - 60px - 10px - 20px - 30px));
  margin-top: 20px;
  .exportToExcelButton {
    float: right;
    margin: 0;
    width: 140px;
    height: 30px;
    margin-bottom: 5px;
    background-color: green;
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;

    display: inline-block;
  }
  .InvoiceControl-Table-Header {
    #InvoiceControl-HeaderName {
      display: inline-block;
      margin: 0;
    }
  }
  .InvoiceControlTable-Container {
    width: 100%;
    max-height: calc((100vh - 60px - 200px - 10px - 30px));
    overflow: auto;

    .JustStaticTable-Container {
      width: 100%;
      td,
      th {
        &:nth-child(1) {
          //phnxId
          width: 15%;
        }
        &:nth-child(2) {
          //legalId
          width: 15%;
        }
        &:nth-child(3) {
          //type
          width: 12%;
        }
        &:nth-child(4) {
          //status
          width: 12%;
        }
        &:nth-child(5) {
          //lastmesasge
          width: 46%;
        }
      }
    }
  }
}
