@import "../../../CoreStyle/CoreStyle.scss";
.JustSearchInput-Container {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
    &:focus {
        outline: none;
        border: none;
    }
    .Input-Spinner {
        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }
    .Input-Label {
        color: #002b45;
        // font: $boldFont !important;
        font-weight: bold;
        line-height: 1.25;
        font-size: 12px;
        margin-bottom: 4px;
        display: block;
    }
    .SearchBox-Container {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(0%, 100%);
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        // height: 400px;
        border: 1px solid #68b2e399;
        z-index: 999;
        // padding-bottom: 5px;
        background-color: rgb(250, 250, 250);
        #foundElement {
            font-size: 13px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            user-select: none;

            cursor: pointer;
            &:hover {
                background-color: #345370;
                color: white;
            }
            padding: 5px;
            border-bottom: 1px solid #68b2e399;
            display: block;
            &:last-child {
                border-bottom: none;
                // padding-bottom: 0;
            }
        }
    }
}
