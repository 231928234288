.JustLanguage-SelectBox {
  position: relative;
  background-color: transparent;
  border: none;
  appearance: none;
  //   min-width: 40px;
  font-size: 14px;
  cursor: pointer;
  //   border-right: 1px solid black;
  margin-right: 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.0);
  border-left: 1px solid rgba(0, 0, 0, 0.0);
  padding: 5px;
  &:hover {
    background-color: #eaf4fb;
    border-right: 1px solid rgba(255, 255, 255, 0.178);
    border-left: 1px solid rgba(255, 255, 255, 0.178);
  }
  display: inline-block;
  // transform: skew(-5deg, 0deg);
  svg {
    width: 18px;
    height: 18px;
  }
  #FlagText {
    margin-right: 5px;
    transform: skew(0deg, 0deg);
  }
  .JustLanguage-Dialog {
    // transform: skew(5deg, 0deg);
    position: absolute;
    min-width: 100px;
    max-width: 150px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 999;
    .SelectedLanguage {
      background-color: #bbddf3a1;
    }
    .JustLanguage-Language {
      &:hover {
        background-color: #bbddf3;
      }
      padding: 5px;
      #JustLanguage-LanguageIcon {
        margin-right: 5px;
      }
      #JustLanguage-LanguageText {
      }
    }
  }
}
