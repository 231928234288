.Invoice-Container {
  position: relative;
  max-height: calc((100vh - 60px - 10px - 20px - 30px) * 35 / 100);
  .Invoice-Lock {
    height: calc(100% - 48px);
  }
  .Invoice-Table-Header {
    #Invoice-HeaderName {
      display: inline-block;
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .Invoice-Table-Container {
    position: relative;
    width: 100%;
    max-height: calc((100vh - 60px - 10px - 20px - 30px) * 35 / 100 - 50px);
    overflow: auto;

    .JustStaticTable-Container {
      width: 2000px;

      td,
      th {
        &:nth-child(6) {
          width: 125px;
        }
        // &:nth-child(7) {
        //   width: 5%;
        // }
        &:nth-child(8) {
          width: 18%;
        }
        &:nth-child(9) {
          width: 6%;
        }
        &:nth-child(10) {
          width: 6%;
        }
        &:nth-child(12) {
          width: 8%;
        }
      }
    }
    //   margin-top: 10
    //   margin-left: 10%;
  }
}
