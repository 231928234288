.IncomingInvoice-Container {
    position: relative;
    max-height: calc((100vh - 60px - 10px - 20px - 30px));
    .IncomingInvoice-Lock {
        height: calc(100% - 30px);
    }
    .IncomingInvoice-Table-Header {
        margin-top: 20px;
        #IncomingInvoice-HeaderName {
            display: inline-block;
            margin: 0;
        }
    }

    .IncomingInvoice-Table-Container {
        position: relative;
        width: 100%;
        max-height: calc((100vh - 60px - 200px - 10px - 30px));
        overflow: auto;

        .JustTable-Container {
            width: 1500px;
            td,
            th {
                &:nth-child(2) {
                    width: 5.5%;
                }
                &:nth-child(3) {
                    //integrated,e-invoice
                    width: 4.5%;
                }
                &:nth-child(4) {
                    //Invoice date
                    width: 8%;
                }
                &:nth-child(5) {
                    //Invoice Id
                    width: 12%;
                }
                &:nth-child(6) {
                    //cnumber
                    width: 8%;
                }
                &:nth-child(7) {
                    //cname
                    width: 26%;
                }
                &:nth-child(8) {
                    //i amount
                    width: 8%;
                }
                &:nth-child(9) {
                    //i amount as try
                    width: 9%;
                }
                &:nth-child(10) {
                    //i currency
                    width: 8%;
                }
                // &:nth-child(11) { //e rate
                //     width: 6%;
                // }
            }
        }
    }

    .JustTable-ButtonContainer {
        position: relative;
        display: inline-block;
        // margin-left: 10px;
        margin-bottom: 5px;
        vertical-align: top;
        float: right;
        > div {
            display: inline-block;
        }
        .Hamburger-Button {
            height: 30px;
            font-size: 14px;
            margin-left: 10px;
        }
        .JustTable-NewInvoice-Button {
            height: 30px;
            font-size: 14px;
            background-color: #4caf50;
            // margin-left: 10px;
        }
    }
}
