.Transaction-Container {
  //100vh - 60px - 10px - 20px TableContainer
  position: relative;
  max-height: calc((100vh - 60px - 10px - 20px - 30px) * 45 / 100);
  .Transaction-Lock {
    height: calc(100% - 30px);
  }
  .Transaction-Table-Header {
    #Transaction-HeaderName {
      display: inline-block;
      margin: 0;
    }
    #Table-SaveButton {
      float: right;
      margin-bottom: 10px;
    }
    #Table-ProcessButton {
      float: right;
      margin-right: 10px;
    }
  }

  .Transaction-Table-Container {
    position: relative;
    width: 100%;
    max-height: calc((100vh - 60px - 10px - 20px - 30px) * 45 / 100 - 30px - 10px);
    overflow: auto;
    .JustTable-Container {
      width: 4340px;
      .COPIEDTRANSACTION-ROW {
        // background-color: #dbeffd;
        td[data-lock="on"] {
          // background-color: #dbeffd;
        }
      }
      .COPIED-ROW {
        background-color: #dbeffd;
        td[data-lock="on"] {
          background-color: #dbeffd;
        }
      }

      td,
      th {
        &:nth-child(4), //d's
        &:nth-child(5), //iL
        &:nth-child(6), //iC
        &:nth-child(7), //icT
        &:nth-child(8),
        &:nth-child(9) //ITS
        {
          // iS
          width: 1.25%;
        }
        // &:nth-child(9) {
        //   //customer number
        //   width: 3.5%;
        // }
        &:nth-child(12) {
          //currency
          width: 2%;
        }
        &:nth-child(13) {
          //total revenue amount
          width: 3.5%;
        }
        &:nth-child(14) {
          //calculated rebate ratio
          width: 3.5%;
        }
        &:nth-child(15) {
          //calculated rebate amount
          width: 3.75%;
        }
        &:nth-child(16) {
          // rebate ratio
          width: 3%;
        }
        &:nth-child(17) {
          // rebate amount
          width: 3.5%;
        }
        &:nth-child(18) {
          //manual charge back amount
          width: 5.35%;
        }
        &:nth-child(19) {
          //invoice charge back amount
          width: 5.35%;
        }
        &:nth-child(20) {
          //octet
          width: 1.25%;
        }
        &:nth-child(21) {
          //email
          width: 9.5%;
        }
        &:nth-child(22) {
          //updated by
          width: 5%;
        }
        &:nth-child(23),  //update date
        &:nth-child(24) {
          //copied period
          width: 125px;
        }
        &:nth-child(25), //buyer acount
        &:nth-child(26) {
          //vendor
          width: 4%;
          //buyers
        }
        // &:nth-child(21),
        // &:nth-child(22) {
        //   width: 10%;
        // }
      }
    }
    //   margin-top: 10
    //   margin-left: 10%;
  }

  .JustTable-ButtonContainer {
    position: relative;
    display: inline-block;
    // margin-left: 10px;
    margin-bottom: 5px;
    vertical-align: top;
    float: right;
    > div {
      display: inline-block;
    }
    .Hamburger-Button {
      height: 30px;
      font-size: 14px;
      margin-left: 10px;
    }
    .JustTable-SaveAll-Button {
      height: 30px;
      font-size: 14px;
      // margin-left: 10px;
    }
  }
}
