.HistoryModal-Container {
  max-width: 1200px;
  min-width: 450px;
  max-height: 500px;
  min-height: 300px;
  //   div:nth-child(1) {
  //     margin-top: 68px;
  //   }
  .JustCanvas-JustCanvas {
    width: 450px;
    height: 300px;
  }
  #HistoryModal-Documentname {
    cursor: pointer;
    &:hover {
      background-color: #bbddf3;
    }
  }
  .JustLockComponent-Lock{
      height: calc(100% - 100px);
  }
}
