@import "../../../CoreStyle/CoreStyle.scss";

.InvoiceControl-Header {
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  .datePickerContainer {
    width: 500px;
    height: 45px;
    margin-top: 15px;
    display: inline-block;
    .datepicker_container {
      width: 250px !important;
    }
    .datepicker_container .dateInputs {
      padding-top: 5px;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: $groupBlue;
      //   text-decoration-style: wavy;

      height: 50px !important;
    }
  }
  .SearchButton {
    float: right;
    width: 140px;
    height: 40px;
    margin-top: 20px;
    // font-size: 24px;
    // vertical-align: auto;
  }
}
