.AccountingVessel-Container {
    width: 100%;
    height: 100%;
    #HeaderText {
    }
    .AccountingVessel-Content-Header {
        position: relative;
        width: 100%;
        height: 50px;
        .AccountingVessel-AddRate {
            position: absolute;
            right: 0;
        }
    }
    .AccountingVessel-Content-Container {
        .AccountingVessel-Table-Container {
            height: 650px;
            overflow-y: auto;
            td,
            th {
                &:nth-child(2) {
                    // Rented
                    width: 5%;
                }
                &:nth-child(3) {
                    // Code
                    width: 15%;
                }
                &:nth-child(4) {
                    // VesselCode
                    width: 15%;
                }
            }
        }
    }
}
