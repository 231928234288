@import "../../../CoreStyle/CoreStyle.scss";
.IncomingInvoice-Header {
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    .IncomingInvoice-Header-Date-Container {
        width: 330px;
        display: inline-block;
        .Date-Container {
            display: inline-block;
            #Date-Label {
                margin: 0;
                padding: 0;
                margin-left: 1px;
                display: block;
                font-size: 14px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                margin-left: 5px;
            }
        }

        .Date-Container-Year {
            width: 120px;
            display: inline-block;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
                > select {
                    margin-bottom: 0px;
                }
            }
        }
        .Date-Container-StartM {
            width: 100px;
            display: inline-block;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
                > select {
                    margin-bottom: 0px;
                }
            }
        }
        .Date-Container-EndM {
            width: 100px;
            display: inline-block;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
                > select {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .IncomingInvoice-Header-Customer-Container {
        display: inline-block;
        width: 370px;
        .Customer-Container {
            margin-left: 5px;
            display: inline-block;
            #Customer-Container-Label {
            }
            .CustomerName {
                width: 200px;
                > div {
                    width: 200px;
                }
            }

            .CustomerCode {
                width: 160px;
                > div {
                    width: 160px;
                }
            }
        }
    }
    .IncomingInvoice-Header-Radio-Container {
        display: inline-block;
        margin-left: 10px;
        vertical-align: bottom;
        margin-bottom: -4px;

        .Import-Copies {
            // margin-top: 14px;
            display: inline-block;
        }
        .Export-Copies {
            // bottom: 0px;

            display: inline-block;
        }
    }
    .Radio-Button {
        margin-bottom: 0;
        cursor: pointer;
        margin-right: 15px;
        span {
            font-size: 14px;
            padding-left: 25px;
            display: inline-block;
            span {
                padding-left: 0;
                margin-bottom: 0;
            }
        }
        i {
            width: 16px;
            height: 16px;
            top: 50%;
            transform: translateY(-50%);
            &::before {
                content: "";
                width: 10px !important;
                height: 10px !important;
            }
            &::after {
                content: "";
                width: 20px !important;
                height: 20px !important;
            }
        }
    }
    .Radio-Container {
        // margin-left: 10px;
    }
    .Input-Label {
        color: #002b45;
        font-weight: bold;
        line-height: 1.25;
        font-size: 12px;
        margin-bottom: 4px;
        display: block;
    }
    .SearchButton {
        float: right;
        width: 140px;
        height: 40px;
        margin-top: 20px;
        // font-size: 24px;
        // vertical-align: auto;
    }
    .Integrated-Container{
        display: inline-block;
        width: 120px;
        margin-left: 5px;
      }
      .EInvoice-Container{
        display: inline-block;
        width: 120px;
        margin-left: 5px;
      }

}
