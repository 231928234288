.Modal-Header {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding-left: 5px;
    .CustomerInformation-Edit {
        display: inline-block;
        .CustomerName {
            display: inline-block;
            // margin-left: 5px;
            width: 400px;
            height: 40px;

            .Input-Label {
                color: #002b45;
                // font: $boldFont !important;
                font-weight: bold;
                line-height: 1.25;
                font-size: 12px;
                margin-bottom: 4px;
                display: block;
            }
            .CustomerName-Input {
                color: #4d4e4c;
            }
        }
        .CustomerNumber {
            display: inline-block;
            margin-left: 10px;
            width: 200px;
            height: 40px;

            .Input-Label {
                color: #002b45;
                // font: $boldFont !important;
                font-weight: bold;
                line-height: 1.25;
                font-size: 12px;
                margin-bottom: 4px;
                display: block;
            }
            .CustomerNumber-Input {
                color: #4d4e4c;
            }
        }
        margin-bottom: 10px;
    }
    .PeriodInformation {
        width: 330px;
        display: inline-block;
        margin-left: 10px;
        .Date-Container {
            display: inline-block;
            #Date-Label {
                margin: 0;
                padding: 0;
                margin-left: 1px;
                display: block;
                font-size: 14px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                margin-left: 5px;
            }
        }

        .Date-Container-Year {
            width: 120px;
            display: inline-block;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
                > select {
                    margin-bottom: 0px;
                }
            }
        }
        .Date-Container-StartM {
            width: 100px;
            display: inline-block;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
                > select {
                    margin-bottom: 0px;
                }
            }
        }
        .Date-Container-EndM {
            width: 100px;
            display: inline-block;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
                > select {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .GetTransactions-Button {
        height: 40px;
        margin-left: 50px;
    }
}
