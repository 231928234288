@import "../../../../../CoreStyle/CoreStyle.scss";
.EditInvoiceModalContent-Container {
    margin-top: 10px;
    padding-left: 5px;
    height: 515px;
    .Radio-Content {
        // border-top: 1px solid black;
        // border-bottom: 1px solid black;

        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        margin-top: 10px;
        // padding-bottom: 10px;
        // margin-top: 20px;
        .radioButtonInvoice {
            i {
                top: 5px !important;
            }
            span {
                padding: 0;
                padding-left: 22px;
                font-size: 12px;
            }
            display: inline-block;
            &:nth-child(2) {
                margin-left: 20px;
            }
        }
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
    .InputWithLabel {
        display: inline-block;
        margin-left: 5px;
        width: 200px;
        height: 40px;
        input {
            font-size: 14px;
            &:disabled {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
    .Input-Label {
        color: #002b45;
        // font: $boldFont !important;
        font-weight: bold;
        line-height: 1.25;
        font-size: 12px;
        margin-bottom: 4px;
        display: block;
    }
    #GUIDInput {
        margin-left: 20px;
        width: 310px;
    }
    .getInvoiceButton {
        height: 40px;
        margin-left: 20px;
    }
    .ContentSelf-Container {
        margin-top: 20px;
        margin-bottom: 10px;
        height: 270px;
        // overflow: hidden;
        // height: 270px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        .JustCanvas-JustCanvas {
            position: absolute;
            z-index: 999;
        }
        .LeftContent-Container {
            position: relative;
            display: inline-block;
            width: calc(50% - 0.5px);
            height: 100%;
            margin-right: 0.5px;
            background-color: white;
            #invoicePeriod {
                position: absolute;
                top: 10px;
                width: 250px;
                input {
                    width: 250px;
                }
            }
            #invoiceNumber {
                position: absolute;
                left: 290px;
                width: 250px;
            }
            #datePicker_mah {
                position: absolute;
                top: 88px;
                left: 292px;
                width: 250px;
                input {
                    width: 250px;
                }
            }
            #datePicker_payment {
                position: absolute;
                top: 88px;
                // left: -5px;
                width: 250px;
                input {
                    width: 250px;
                }
            }
            #invoiceRate {
                position: absolute;
                top: 160px;
                left: 288px;
                width: 250px;
            }
            #differenceAmountAsTry {
                position: absolute;
                top: 240px;
                left: 288px;
                width: 250px;
            }
            #invoiceDescription {
                position: absolute;
                top: 160px;
                left: -5px;
                width: 250px;
                textarea {
                    width: 250px;
                    resize: none;
                    font-size: 14px;
                    &:focus {
                        outline: none !important;
                        border: 1.5px solid #68b2e3;
                        box-shadow: 0 0 5px 0px #68b2e3;
                    }
                    &:disabled {
                        background-color: white;
                    }
                    height: 120px !important;
                }
            }
        }
        .RightContent-Container {
            position: relative;
            display: inline-block;
            width: calc(50% - 0.5px);
            margin-left: 0.5px;
            height: 100%;
            background-color: white;
            #invoiceAmountAsTry {
                position: absolute;
                left: 320px;
                width: 250px;
            }

            #watAmountAsTL {
                position: absolute;
                left: 320px;
                top: 80px;
                width: 250px;
            }
            #invoiceAmountTL {
                position: absolute;
                top: 160px;
                left: 320px;
                width: 250px;
            }
            #invoiceAmount {
                position: absolute;
                // top: 80px;
                left: 30px;
                width: 250px;
            }
            #currency {
                position: absolute;
                // top: 80px;
                left: 230px;
                width: 50px;
                select {
                    padding: 0;
                    padding-left: 5px;
                }
                div span {
                    width: 25px;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            #watAmount {
                position: absolute;
                top: 80px;
                left: 30px;
                width: 250px;
            }
            #invoiceTotal {
                position: absolute;
                top: 160px;
                left: 30px;
                width: 250px;
            }
        }
        // height: 500px;
        input {
            display: inline-block;
        }
        .DateField-Wrapper {
            display: inline-block;
            width: 200px;
            position: relative;
            height: 60px;
            #declarationDate-Label {
                width: 100%;
                font-size: 12px;
                position: absolute;
                top: -8px;
                left: 0;
            }
            > div {
                margin-top: 10px;
                width: 200px;
                height: 40px;
            }
            input {
                &:disabled {
                    background-color: #ffffff;
                    border: 1px solid #e4e5e4;
                }
                width: 200px;
                height: 40px;
                font-size: 14px;
                padding-left: 10px;
                font-family: $regularFont;
                &::placeholder {
                    color: rgba(77, 78, 76, 0.2);
                }
            }
            input {
                &:focus {
                    outline: none !important;
                    border: 1.5px solid #68b2e3;
                    box-shadow: 0 0 5px 0px #68b2e3;
                }
            }
        }
    }
}
