.NewInvoiceTable-Container {
    overflow: auto;
    margin-top: 10px;
    max-height: 160px;
    min-height: 150px;
    .JustStaticTable-Container {
        padding: 1px;
        // width: 1000px;
        td[data-lock="on"],th[data-lock="on"]{
            background-color: white;
        }
        td,
        th {
            &:nth-child(2) { //isCopied
                width: 6%;
            }
            &:nth-child(3) { //period
                width: 12%;
            }
            &:nth-child(4) { //c.number
                width: 15%;
            }
            &:nth-child(5) { //c.name
                width: 37%;
            }
            &:nth-child(6) { //rAmount
                width: 11%;
            }
            &:nth-child(7) { //rRate
                width: 7%;
            }

            // &:nth-child(6) { //currency
            //     width: 6%;
            // }
        }
    }
}
