@import "CoreStyle/CoreStyle";
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: $regularFont;
  overflow-x: auto;
  overflow-y: auto;
  background: #eef0f1 !important;
  background-size: cover;
}
@media only screen and (max-width: 1300px) {
  .App {
    width: 1000px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .App {
    width: 900px !important;
  }
}

.App {
  width: 1260px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pageHeader {
  width: 100%;
  margin-bottom: 15px;
  height: 30px;
  #VesselName {
    display: inline-block;
    // border-bottom: 1px solid black;
  }
  #Custom {
    display: inline-block;
    float: right;
    // border-bottom: 1px solid black;
  }
}
#NotClickable {
  cursor: not-allowed !important;
  background: #e4e5e4 !important;
}
.breadcrumb {
  background: none !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
textarea,
select,
input,
button {
  outline: none !important;
}
.tableSpinning {
  min-height: 100px;
}
.StaticTable {
  .pRow {
    &:hover {
      cursor: default !important;
      background: none !important;
      color: black !important;
    }
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: $whiteHover;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $textMedium;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $groupBlue;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
}
button {
  background: transparent;
}
.RefreshButtonContainer {
  position: absolute;
  top: calc(60px + 12px);
  height: 24px;
  padding-left: 5px;
  // border: 1px solid #68b2e3;
  right: 0;
  // cursor: pointer;
  #refreshDateText {
    // color: #68b2e3;
    float: right;
    font-size: 14px;
    line-height: 30px;
    // user-select: none;
  }
  #refreshDateButton {
    width: 70px;
    height: 25px;
    font-size: 14px;
    float: right;
    padding: 0;
    margin: 0;
    margin-top: 1px;
    margin-left: 10px;
  }
  .refreshPage {
    margin: 0;
    padding: 0;
    margin-top: 4px;
    width: 16px;
    height: 16px;
    color: #68b2e3;
    float: right;
  }
}
