.JustCanvas-JustCanvas {
  .JustCanvas-WaitForRequest {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .WaitForRequest-Icon {
      display: block;
      svg {
        width: 40px;
        height: 40px;
      }
      margin-left: auto;
      margin-right: auto;
    }
    .SMALL-ICON {
      svg {
        width: 30px !important;
        height: 30px !important;
      }
    }
    span#WaitForRequest-Text {
      font-size: 24px;
    }
  }
}
