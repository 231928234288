.JustLockComponent-Lock {
  position: absolute;
  // top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  z-index: 9999;
  .JustLockComponent-IconText-Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    #JustLockComponent-Icon {
      display: block;
      text-align: center;
      svg {
        fill: rgba(0, 0, 0, 0.8);
        color: rgba(0, 0, 0, 0.8);
        width: 64px;
        height: 64px;
      }
    }
    #JustLockComponent-Text {
      display: block;
      text-align: center;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
